<template>
  <div class="">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item>
        <el-upload
                ref="upload"
                :action="uploadUrl"
                name="file"
                :data="picdata"
                :show-file-list="false"
                :multiple="true"
                accept=".pdf,.doc,.docx,.ppt,.pptx,.zip,.rar,.mp4,.jpg,.jpeg,.png,.avi"
                :headers="headers"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
                :on-error="uploadError">
          <el-button icon="el-icon-upload2">上传文件</el-button>
          <span style="margin-left: 10px; color: #999">50M以内的文件</span>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import config from "../config/config";
  import {getToken} from "@/utils/auth";

  export default {
    data() {
      return {
        uploadUrl: config.HTTP_REQUEST_URL + 'admin/1.0/common/uploadimg',
        headers: {
          authorization: 'Bearer ' + getToken()
        },
        picdata: {
          type: 'file',
          file_type: 4
        },
        fileNum: this.chooseNum
      }
    },
    props: {
      chooseNum: Number,
      selectFunc: Function
    },
    created() {
    },
    mounted() {
    },
    methods: {
      beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 50;
        if (!isLt2M) {
          this.$message.error('上传视频大小不能超过 50MB!');
        }
        return isLt2M;
      },
      uploadSuccess(res, file) {
        var that = this
        if(res.errcode == 0) {
          this.selectFunc(res.data)
          that.success('上传成功')
        } else {
          that.$message.error(res.errmsg)
        }
      },
      uploadError(res, file) {
        this.$message.error('上传失败，请重新上传!');
      }
    }
  }
</script>

<style scoped>

</style>
