<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="LOGO">
        <SelectImgs :selectNum="1" :selectData="[info.logo]" :selectFunc="picChoose" :idx="3"></SelectImgs>
        <div style="margin-top: 40px">140*40px</div>
      </el-form-item>
      <el-form-item label="icp">
        <el-input v-model="info.icp" placeholder="请输入icp"></el-input>
      </el-form-item>
      <el-form-item label="版权信息">
        <el-input v-model="info.copyright" placeholder="请输入版权信息"></el-input>
      </el-form-item>
      <el-form-item label="咨询微信号">
        <el-input v-model="info.wx_num" placeholder="请输入微信号"></el-input>
      </el-form-item>
      <el-form-item label="咨询微信二维码">
        <SelectImgs :selectNum="1" :selectData="[info.wx_qrcode]" :selectFunc="picChoose" :idx="1"></SelectImgs>
        <div style="margin-top: 40px">300*300px</div>
      </el-form-item>
      <el-form-item label="咨询400电话">
        <el-input v-model="info.phone" placeholder="请输入号码"></el-input>
      </el-form-item>
      <el-form-item label="咨询邮箱">
        <el-input v-model="info.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="售后微信二维码">
        <SelectImgs :selectNum="1" :selectData="[info.sale_wx_qrcode]" :selectFunc="picChoose" :idx="2"></SelectImgs>
        <div style="margin-top: 40px">300*300px</div>
      </el-form-item>
      <el-form-item label="售后400电话">
        <el-input v-model="info.sale_phone" placeholder="请输入号码"></el-input>
      </el-form-item>
      <el-form-item label="售后邮箱">
        <el-input v-model="info.sale_email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="HR名称">
        <el-input v-model="info.hr_name" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="HR邮箱">
        <el-input v-model="info.hr_email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="HR电话">
        <el-input v-model="info.hr_phone" placeholder="请输入电话"></el-input>
      </el-form-item>
      <el-form-item label="微博地址">
        <el-input v-model="info.weibo" placeholder="请输入地址"></el-input>
      </el-form-item>
      <el-form-item label="SEO标题">
        <el-input v-model="info.seo_title" placeholder="请输入SEO标题"></el-input>
      </el-form-item>
      <el-form-item label="SEO关键词">
        <el-input v-model="info.seo_keywords" placeholder="请输入SEO关键词"></el-input>
      </el-form-item>
      <el-form-item label="SEO描述">
        <el-input type="textarea" :rows="3" v-model="info.seo_desc" placeholder="请输入SEO描述"></el-input>
      </el-form-item>
      <el-form-item label="底部外联图标">
        <el-button type="warning" size="mini" @click="addIco()">添加图标</el-button>
        <div class="list">
          <div class="item" v-for="(item,index) in info.icos" :key="index">
            <div style="display: flex;">
              <SelectImgs :selectNum="1" :selectData="[item.img]" :selectFunc="icoChoose" :idx="index"></SelectImgs>
              <div>
                <el-input v-model="item.url" placeholder="请输入链接" style="width: 300px;"></el-input>
                <div>100*100px</div>
                <el-button type="text" size="small" @click="delIco(index)">删除</el-button>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </el-form-item>
      <!--el-form-item label="手机号">
        <el-input v-model="info.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item-->
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

  import SelectImgs from '../../components/SelectImgs'
  import VideoUpload from "@/components/VideoUpload";
  export default {
    data() {
      return {
        info: {
          title: '',
          logo: '',
          icp: '',
          copyright: '',
          seo_title: '',
          seo_keywords: '',
          seo_desc: '',
          mobile: '',
          icos: []
        },
        source_domain: this.config.SOURCE_DOMAIN,
        rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' }
          ]
        }
      };
    },
    components: {
      SelectImgs
    },
    created() {
      this.getInfo()
    },
    mounted() {
    },
    computed: {
    },
    methods: {
      addIco() {
        this.info.icos.push({img:'',url:''})
      },
      delIco(index) {
        this.info.icos.splice(index,1)
      },
      picChoose(item,idx) {
        if(idx==1) {
          this.info.wx_qrcode = item.pic
        } else if(idx==2) {
          this.info.sale_wx_qrcode = item.pic
        } else if(idx==3) {
          this.info.logo = item.pic
        }
      },
      icoChoose(item,idx) {
        this.info.icos[idx].img = item.pic
      },
      getInfo() {
        var that = this
        this.$api.merchant.sysDetail( function (res) {
          if(res.errcode == 0) {
            that.info = res.data
          }
        })
      },
      submitForm(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = that.info
            this.$api.merchant.sysEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .list .item {
    display: flex;
    margin-bottom: 10px;
  }
  .list .item>div {
    margin-right: 10px;
  }
</style>
